import { ReactComponent as NoteIcon } from "src/assets/svg/note.svg";
import { ReactComponent as ErrorIcon } from "src/assets/svg/error.svg";
import { ReactComponent as CrossIcon } from "src/assets/svg/cross.svg";

import { useState } from "react";
import { formattedNumber } from "src/helper";
import RequestInformation from "./Request-information";
import TradeMark from "./Trade-mark";

const CalculateFooter = () => {
    const [number, setNumber] = useState("");
    const [result, setResult] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [modal, setModal] = useState<"not-set" | "legal" | "emarketo">(
        "not-set"
    );

    const onkeydownInput = (e: any) => {
        if (e.key === "Enter") {
            calculate();
        }
    };

    const onChangeInput = (e: any) => {
        const { value } = e.target;
        if (isNaN(value)) {
            if (!number) {
                setError("Invalid number");
                return;
            } else {
                return;
            }
        } else if (error) {
            setError("");
        }
        setNumber(value);
    };

    const calculate = () => {
        if (!number) return setError("Invalid data");
        const res = formattedNumber(Number(number) * 0.05 * 200 * 100);
        setResult(String(res));
    };

    const requestInformation = () => {
        setModal("emarketo");
    };
    const showLegal = () => {
        setModal("legal");
    };

    return (
        <>
            <div className="c-edata__calculate">
                <div className="c-edata__calculate-bg">
                    <NoteIcon className="note-svg blue-note" fill="#0A1E37" />
                    <NoteIcon className="note-svg green-note" fill="#66B8A9" />
                    <NoteIcon
                        className="note-svg light-blue-note"
                        fill="#1B3064"
                    />
                </div>
                <h2 className="c-edata__calculate-title">
                    Calculate the potential cost of manual transcription error
                    in your processes
                </h2>
                <div className="c-edata__calculate-form">
                    <h3 className="c-edata__calculate-form__label">
                        Number of batches
                    </h3>
                    <p className="c-edata__calculate-form__sub-label">
                        How many raw material batches do you receive annually
                    </p>
                    <div
                        className={`c-edata__calculate-form__input-container ${
                            error ? "input-error" : ""
                        }`}
                    >
                        <input
                            className={`c-edata__calculate-form__input`}
                            type="text"
                            inputMode="numeric"
                            value={number}
                            onChange={onChangeInput}
                            onKeyDown={onkeydownInput}
                        />
                        {number && (
                            <CrossIcon
                                className="edata-form-clear"
                                onClick={() => {
                                    setNumber("");
                                    setResult("");
                                }}
                            />
                        )}
                        {error && <ErrorIcon className="edata-form-error" />}
                        {error ? (
                            <span className="edata-form-error-message">
                                {error}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                    <h3 className="c-edata__calculate-form__label">Result*</h3>
                    <h2 className="c-edata__calculate-form__result">
                        {result ? result : ""}
                    </h2>
                    <button
                        className="c-edata__calculate-form__submit button button--primary"
                        onClick={calculate}
                    >
                        Calculate
                    </button>
                    <p className="c-edata__calculate-form__footnote">
                        *calculation based off of FTE savings associated with
                        typical entry error investigation
                    </p>
                </div>
                <div className="c-edata__legal-request__container">
                    <button
                        className="button button--primary"
                        onClick={requestInformation}
                    >
                        Request more information
                    </button>
                    <button
                        className="button button--tertiary"
                        onClick={showLegal}
                    >
                        Legal
                    </button>
                </div>
            </div>
            {modal !== "not-set" && (
                <div
                    className="c-edata__modal edata__fade-in"
                    onClick={() => setModal("not-set")}
                >
                    <div
                        className="c-edata__modal-container edata__fade-in"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {modal === "legal" ? (
                            <TradeMark
                                closeOverlay={() => setModal("not-set")}
                            />
                        ) : modal === "emarketo" ? (
                            <RequestInformation
                                closeOverlay={() => setModal("not-set")}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default CalculateFooter;
