import {
    useRive,
    Layout,
    Alignment,
    Fit,
    RiveEventType,
    EventType,
} from "@rive-app/react-canvas";
import { useEffect } from "react";
import { STAGE_1_IS_PRESS, cytiva_databridge } from "src/constants";
import { scrollTo } from "src/helper";

export const Explore = () => {
    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_ExploreMoreButton",
        stateMachines: "sm_ExploreMoreButton",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });
    const onRiveEventReceived = (riveEvent: any) => {
        const eventData = riveEvent.data;
        if (eventData.type === RiveEventType.General) {
            if (eventData.name === STAGE_1_IS_PRESS) {
                scrollTo(2);
            }
        }
    };

    useEffect(() => {
        if (rive) {
            rive.on(EventType.RiveEvent, onRiveEventReceived);
        }
    }, [rive]);
    return <RiveComponent />;
};

export const Loader = () => {
    const { RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_WorkflowLoader",
        stateMachines: "sm_WorkflowLoader",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    return <RiveComponent />;
};
