import React, { useEffect, useState } from "react";
import ThankYou from "./ThankYou";

type RequestInformationProps = {
    closeOverlay: () => void;
};

const RequestInformation: React.FC<RequestInformationProps> = ({
    closeOverlay,
}) => {
    const [submited, setSubmited] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);
    function appendScript(
        baseUrl: string,
        setScriptLoaded: (b: boolean) => void
    ) {
        if ((window as any).MktoForms2) return setScriptLoaded(true);

        const script = document.createElement("script");
        script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
        script.onload = () =>
            (window as any).MktoForms2 ? setScriptLoaded(true) : null;
        document.body.appendChild(script);
    }

    useEffect(() => {
        if (scriptLoaded) {
            (window as any).MktoForms2.loadForm(
                "//info.cytivalifesciences.com",
                "614-VFU-334",
                17692
            );
            setFormLoaded(true);
            return;
        }
        if (!scriptLoaded) {
            appendScript("//info.cytivalifesciences.com", setScriptLoaded);
        }
    }, [scriptLoaded]);

    useEffect(() => {
        if (scriptLoaded && formLoaded) {
            (window as any).MktoForms2.whenReady(function (form: any) {
                //Add an onSuccess handler
                form.onSuccess(function (values: any, followUpUrl: any) {
                    //get the form's jQuery element and hide it
                    // $('#YOUR-FORM').hide();
                    // $('#TY-TEXT').show();
                    //return false to prevent the submission handler from taking the lead to the follow up url.

                    // console.log(values, followUpUrl);
                    setSubmited(true);
                    return false;
                });
            });
        }
    }, [scriptLoaded, formLoaded]);

    return (
        <>
            <div
                className="c-edata__trademark-section"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="c-edata__trademark-top">
                    <img
                        className="c-edata__trademark-top__logo"
                        src="favicon.ico"
                        width="40"
                        alt="Cytiva logo"
                    />
                    <button
                        className="c-edata__trademark-top__close"
                        onClick={(e) => {
                            e.preventDefault();
                            closeOverlay();
                            setSubmited(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <span className="c-edata__spacer"></span>
                {submited && <ThankYou />}
                {scriptLoaded && formLoaded && !submited && (
                    <form id="mktoForm_17692"></form>
                )}
            </div>
        </>
    );
};

export default RequestInformation;
