import CalculateFooter from "./components/Calculate.footer";
import DataBridgeFooter from "./components/Data.bridge.footer";
import Hero from "./components/Hero";
import ReusableRiveComponent from "./components/Rive.reusable.component";
import WorkflowFooter from "./components/Workflow.footer";

function App() {
    return (
        <div className="app__container">
            <Hero />
            <ReusableRiveComponent stage={1} />
            <ReusableRiveComponent stage={2} />
            <ReusableRiveComponent stage={3} />
            <ReusableRiveComponent stage={4} />
            <ReusableRiveComponent stage={5} />
            <ReusableRiveComponent stage={6} />
            <ReusableRiveComponent stage={7} />
            <ReusableRiveComponent stage={8} />
            <DataBridgeFooter />
            <WorkflowFooter />
            <CalculateFooter />
        </div>
    );
}

export default App;
