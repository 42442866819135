import ContentComponentSwitch from "./stages/Content.component.switch";
import RiveComponentSwitch from "./stages/Rive.component.switch";
import { Explore } from "./rive-elements/Elements";

const RiveLoopComponent = ({ stage }: { stage: number }) => {
    if (stage === 1) {
        return <FirstStage />;
    } else
        return (
            <div
                className={`c-edata__stage c-edata__stage-${stage} ${
                    stage === 4 && "reverse-row"
                }`}
            >
                <div className={`c-edata__content c-edata__content-${stage}`}>
                    <ContentComponentSwitch stage={stage} />
                </div>
                <div className={`c-edata__rive c-edata__rive-${stage}`}>
                    <RiveComponentSwitch stage={stage} />
                </div>
            </div>
        );
};

const FirstStage = () => {
    return (
        <div className={`c-edata__stage c-edata__stage-1`}>
            <div className="c-edata__stage-1__row-1">
                <div className="c-edata__content">
                    <ContentComponentSwitch stage={1} />
                </div>
                <div className={`c-edata__rive c-edata__rive-1`}>
                    <RiveComponentSwitch stage={1} />
                </div>
            </div>
            <div className="c-edata__stage-1__row-2">
                <h4 className="explore-more__text">Explore more</h4>
                <div className="explore-more__rive">
                    <Explore />
                </div>
            </div>
        </div>
    );
};

export default RiveLoopComponent;
