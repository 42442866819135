import {
    useRive,
    Layout,
    Alignment,
    Fit,
    RiveEventType,
    EventType,
} from "@rive-app/react-canvas";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
    STAGE_2_BUTTON_PRESS,
    STAGE_EXEC_PRESS,
    STAGE_FDA_IS_CORRECT,
    cytiva_databridge,
} from "src/constants";
import { scrollTo } from "src/helper";

const RiveComponentSwitch = ({ stage }: { stage: number }) => {
    switch (stage) {
        case 1:
            return <Rive_1 />;
        case 2:
            return <Rive_2 />;
        case 3:
            return <Rive_3 />;
        case 4:
            return <Rive_4 />;
        case 5:
            return <Rive_5 />;
        case 6:
            return <Rive_6 />;
        case 7:
            return <Rive_7 />;
        case 8:
            return <Rive_8 />;

        default:
            return <></>;
    }
};

const Rive_1 = () => {
    const { RiveComponent } = useRive({
        src: cytiva_databridge,
        stateMachines: "sm_01_Transcribe",
        artboard: "art_01_Transcribe",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    return <RiveComponent />;
};

const Rive_2 = () => {
    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_02_ManuLeadTime",
        stateMachines: "sm_02_ManuLeadTime",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });
    const onRiveEventReceived = (riveEvent: any) => {
        const eventData = riveEvent.data;
        if (eventData.type === RiveEventType.General) {
            if (eventData.name === STAGE_2_BUTTON_PRESS) {
                scrollTo(3);
            }
        }
    };

    useEffect(() => {
        if (rive) {
            rive.on(EventType.RiveEvent, onRiveEventReceived);
        }
    }, [rive]);

    return <RiveComponent />;
};

const Rive_3 = () => {
    const { ref, inView, entry } = useInView({
        threshold: 1,
    });

    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_02_Numbers",
        stateMachines: "sm_02_Numbers",
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    useEffect(() => {
        if (rive && inView) {
            rive.play();
        }
        if (rive && !inView) {
            rive.reset({
                artboard: "art_02_Numbers",
                stateMachines: "sm_02_Numbers",
            });
        }
    }, [rive, inView]);

    return (
        <div ref={ref} className="rive__inner-container">
            <RiveComponent />
        </div>
    );
};

const Rive_4 = () => {
    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_03_FDA_warning",
        stateMachines: "sm_03_FDA_warning",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    const onRiveEventReceived = (riveEvent: any) => {
        const eventData = riveEvent.data;
        if (eventData.name === STAGE_FDA_IS_CORRECT) {
            scrollTo(5);
        }
    };

    useEffect(() => {
        if (rive) {
            rive.on(EventType.RiveEvent, onRiveEventReceived);
        }
    }, [rive]);

    return <RiveComponent />;
};

const Rive_5 = () => {
    const { ref, inView, entry } = useInView({
        threshold: 1,
    });

    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_03_Numbers",
        stateMachines: "sm_03_Numbers",
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    useEffect(() => {
        if (rive && inView) {
            rive.play();
        }
        if (rive && !inView) {
            rive.reset({
                artboard: "art_03_Numbers",
                stateMachines: "sm_03_Numbers",
            });
        }
    }, [rive, inView]);

    return (
        <div ref={ref} className="rive__inner-container">
            <RiveComponent />
        </div>
    );
};

const Rive_6 = () => {
    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_04_ExecTopPrio",
        stateMachines: "sm_04_ExecTopPrio",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });
    const onRiveEventReceived = (riveEvent: any) => {
        const eventData = riveEvent.data;
        if (eventData.type === RiveEventType.General) {
            if (eventData.name === STAGE_EXEC_PRESS) {
                scrollTo(7);
            }
        }
    };

    useEffect(() => {
        if (rive) {
            rive.on(EventType.RiveEvent, onRiveEventReceived);
        }
    }, [rive]);

    return <RiveComponent />;
};

const Rive_7 = () => {
    const { ref, inView, entry } = useInView({
        threshold: 1,
    });

    const { rive, RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_04_Numbers",
        stateMachines: "sm_04_Numbers",
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    useEffect(() => {
        if (rive && inView) {
            rive.play();
        }
        if (rive && !inView) {
            rive.reset({
                artboard: "art_04_Numbers",
                stateMachines: "sm_04_Numbers",
            });
        }
    }, [rive, inView]);

    return (
        <div ref={ref} className="rive__inner-container">
            <RiveComponent />
        </div>
    );
};

const Rive_8 = () => {
    const { RiveComponent } = useRive({
        src: cytiva_databridge,
        artboard: "art_05_DataTransfer",
        stateMachines: "sm_05_DataTransfer",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        }),
    });

    return <RiveComponent />;
};

export default RiveComponentSwitch;
