import { ReactComponent as Thumb } from "src/assets/svg/thumb.svg";
import { ReactComponent as Todo } from "src/assets/svg/todo.svg";
import { ReactComponent as Timer } from "src/assets/svg/timer.svg";

const DataBridgeFooter = () => {
    return (
        <div className="c-edata__data-bridge">
            <h2 className="c-edata__data-bridge__title">
                <span className="font-heading-2 color--green">
                    Cytiva data bridge
                </span>
                <br />
                <span className="font-heading-2 color--black">Benefits</span>
            </h2>
            <div className="c-edata__data-bridge__levels">
                <div className="c-edata__data-bridge__levels-icons">
                    <Timer className="data-bridge__icons" />
                    <Todo className="data-bridge__icons" />
                    <Timer className="data-bridge__icons" />
                    <Thumb className="data-bridge__icons" />
                </div>
                <div className="c-edata__data-bridge__levels-content">
                    <div className="c-edata__data-bridge__levels-content__item">
                        <h3>Rapid data access</h3>
                        <p>
                            Immediate access to raw material data for improved
                            decision making
                        </p>
                    </div>
                    <div className="c-edata__data-bridge__levels-content__item">
                        <h3>Improved comparison</h3>
                        <p>Data compare and on demand analysis</p>
                    </div>
                    <div className="c-edata__data-bridge__levels-content__item">
                        <h3>Faster raw materials</h3>
                        <p>
                            Release raw materials faster and with less QC “hands
                            on” time
                        </p>
                    </div>
                    <div className="c-edata__data-bridge__levels-content__item">
                        <h3>Improved acceptance</h3>
                        <p>
                            Improved product acceptance through reduced
                            variability
                        </p>
                    </div>
                </div>
            </div>
            <div className="c-edata__data-bridge__footer">
                <h2>
                    <span className="font-heading-1 color--green">
                        Save time
                    </span>
                    <span className="font-heading-1 color--black">
                        , reduce errors, and improve compliance by digitizing
                        workflows for raw materials processing using data bridge
                    </span>
                </h2>
            </div>
        </div>
    );
};
export default DataBridgeFooter;
