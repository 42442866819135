import { useState } from "react";
import { scrollTo } from "src/helper";

const ContentComponentSwitch = ({ stage }: { stage: number }) => {
    switch (stage) {
        case 1:
            return <Content_1 />;
        case 2:
            return <Content_2 />;
        case 3:
            return <Content_3 />;
        case 4:
            return <Content_4 />;
        case 5:
            return <Content_5 />;
        case 6:
            return <Content_6 />;
        case 7:
            return <Content_7 />;
        case 8:
            return <Content_8 />;
        default:
            return <></>;
    }
};

const Content_1 = () => {
    const [content, setContent] = useState<"1" | "2">("1");

    return (
        <div>
            {content === "1" ? (
                <>
                    <div className="text-content">
                        <h2 className="font-heading-2 color--green">
                            Quick question
                        </h2>
                        <h2 className="font-heading-2 color--black">
                            Are you manually transcribing stacks of CoAs into
                            your QMS?
                        </h2>
                    </div>
                    <div className="row-buttons">
                        <button
                            className="button button--primary"
                            onClick={() => scrollTo(2)}
                        >
                            Yes
                        </button>
                        <button
                            className="button button--secondary"
                            onClick={() => setContent("2")}
                        >
                            No
                        </button>
                    </div>
                </>
            ) : (
                <h2 className="edata__fade-in">
                    <span className="font-heading-2 color--green">
                        ...or spending{" "}
                    </span>
                    <span className="font-heading-2 color--black">
                        too much time sending requests or dealing with
                        non-compliant data systems?
                    </span>
                </h2>
            )}
        </div>
    );
};

const Content_2 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    Drag the slider
                </span>
                <br />
                <span className="font-heading-2 color--black">
                    What is the percentage* of manufacturing lead time
                    associated with manual and paper processes?
                </span>
            </h2>
            <span className="font-note">* approximate</span>
        </div>
    );
};

const Content_3 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    Surprisingly
                </span>
                <span className="font-heading-2 color--black">
                    , 70% is the manufacturing lead time associated with manual
                    and paper processes{" "}
                </span>
                <span className="tooltip-icon">1</span>
                <span className={`tooltip-text`}>
                    Dare to be different: It’s time to revamp collaboration in
                    life sciences contract manufacturing. Accenture Life
                    Sciences. 2019.
                </span>
            </h2>
        </div>
    );
};

const Content_4 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    Can you guess?
                </span>
                <br />
                <span className="font-heading-2 color--black">
                    What percentage of FDA warning letters* cite data integrity
                    as an issue?
                </span>
            </h2>
            <span className="font-note">* years 2020 - 2023</span>
        </div>
    );
};

const Content_5 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    An astounding{" "}
                </span>
                <span className="font-heading-2 color--black">
                    79% of FDA warning letters cite data integrity as an issue{" "}
                </span>
                <span className="tooltip-icon">2</span>
                <span className="tooltip-text">
                    DeLa Torre M. Data integrity trends in 483s and warning
                    letters: Part 1. Redica Systems. May16, 2019. Accessed
                    February, 2024.
                </span>
            </h2>
        </div>
    );
};

const Content_6 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    Drag the slider
                </span>
                <br />
                <span className="font-heading-2 color--black">
                    How many biopharma executives say digital is a top priority
                </span>
            </h2>
        </div>
    );
};

const Content_7 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">Actually</span>
                <span className="font-heading-2 color--black">
                    , no less than 58% of biopharma executives say digital is a
                    top priority{" "}
                </span>
                <span className="tooltip-icon">3</span>
                <span className="tooltip-text">
                    Coming of age digitally. MITSloan Management Review. 2018.
                </span>
            </h2>
        </div>
    );
};

const Content_8 = () => {
    return (
        <div>
            <h2>
                <span className="font-heading-2 color--green">
                    Cytiva data bridge
                </span>
                <br />
                <span className="font-heading-2 color--black">
                    automatically imports your CoA and Lot Genealogy data to
                    your QMS
                </span>
            </h2>
        </div>
    );
};

export default ContentComponentSwitch;
