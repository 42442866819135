import React from "react";

type TradeMarkProps = {
    closeOverlay: () => void;
};

const TradeMark: React.FC<TradeMarkProps> = ({ closeOverlay }) => {
    return (
        <>
            <div
                className="c-edata__trademark-section"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="c-edata__trademark-top">
                    <img
                        className="c-edata__trademark-top__logo"
                        src="favicon.ico"
                        width="40"
                        alt="Cytiva logo"
                    />
                    <button
                        className="c-edata__trademark-top__close"
                        onClick={closeOverlay}
                    >
                        X
                    </button>
                </div>
                <p className="c-edata__trademark-text">
                    Cytiva and the Drop logo are trademarks of Life Sciences IP
                    Holdings Corp. or an affiliate doing business as Cytiva.
                    <span className="c-edata__spacer"></span>
                    Any use of software may be subject to one or more end user
                    license agreements, a copy of, or notice of which, are
                    available on request.
                    <span className="c-edata__spacer"></span>© 2024 Cytiva.
                    <span className="c-edata__spacer"></span>
                    For local office contact information, visit:{" "}
                    <a
                        className="c-edata__trademark-text"
                        style={{ textDecoration: "none" }}
                        href="https://cytiva.com/contact"
                        rel="noreferrer"
                        target="_blank"
                    >
                        cytiva.com/contact
                    </a>
                </p>
            </div>
        </>
    );
};

export default TradeMark;
