const ThankYou = () => {
    return (
        <div className="c-edata__thank-you">
            <p className="font-paragraph-1">
                Thank you for your interest in our Cytiva™ data bridge.
            </p>
            <span className="c-edata__spacer"></span>
            <p className="font-paragraph-1">
                A Cytiva representative will contact you shortly.
            </p>
        </div>
    );
};
export default ThankYou;
