export const scrollTo = (stage: number) => {
    const target = document.querySelector(`.c-edata__stage-${stage}`);
    target?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
    });
};

export const formattedNumber = (number: number) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
