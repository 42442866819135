import { Loader } from "./rive-elements/Elements";

const WorkflowFooter = () => {
    return (
        <div className="c-edata__workflow-wrapper">
            <div className="c-edata__workflow">
                <div className="c-edata__workflow-title font-heading-1 color--black">
                    WORKFLOW
                </div>
                <div className="c-edata__workflow-content">
                    <div className="c-edata__workflow-content__section">
                        <div className="c-edata__workflow-content__section-title">
                            Manual transcription
                        </div>
                        <div className="c-edata__workflow-content__section-col">
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>Paper or PDF CoA receipt</h3>
                            </div>
                            <div className="c-edata__workflow-content__section-col__item workflow-loader">
                                <div className="c-edata__workflow-content__section-col__loader">
                                    <Loader />
                                </div>
                            </div>
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>Upload to document management system</h3>
                            </div>
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>Verify CoA information</h3>
                            </div>
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>Additional quality checks</h3>
                            </div>
                        </div>
                    </div>
                    <div className="c-edata__workflow-content__section">
                        <div className="c-edata__workflow-content__section-title">
                            Automated data bridge
                        </div>
                        <div className="c-edata__workflow-content__section-col">
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>
                                    Direct digital access, integration and
                                    verification
                                </h3>
                            </div>
                            <div className="c-edata__workflow-content__section-col__item">
                                <h3>Additional quality checks</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="c-edata__workflow__footer">
                <h2 className="font-heading-1 color--green">
                    Save hundreds of hours in processing paperwork and remove
                    transcription error by using data bridge
                </h2>
            </div>
        </div>
    );
};
export default WorkflowFooter;
