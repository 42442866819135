import { ReactComponent as NoteIcon } from "src/assets/svg/note.svg";
import { ReactComponent as Logo } from "src/assets/svg/cytiva_logo_hz_white_rev_rgb.svg";

const Hero = () => {
    return (
        <div className="c-edata__hero">
            <div className="c-edata__hero-notes">
                <NoteIcon
                    className="note-svg light-blue-1-note"
                    fill="#0F2346"
                />
                <div className="right-notes">
                    <NoteIcon className="note-svg blue-1-note" fill="#0A1E37" />
                    <NoteIcon className="note-svg blue-2-note" fill="#0A1E37" />
                    <NoteIcon className="note-svg green-note" fill="#66B8A9" />
                    <NoteIcon
                        className="note-svg light-blue-2-note"
                        fill="#0F2346"
                    />
                </div>
            </div>
            <div className="c-edata__hero-content">
                <Logo className="c-edata__hero-content__logo" />
                <div className="c-edata__hero-content__text">
                    <h1 className="c-edata__hero-content__text-title">
                        Manually transcribing data? STOP!
                    </h1>
                    <p className="c-edata__hero-content__text-subtitle">
                        Save time. Integrate Cytiva™ data bridge into your QMS
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Hero;
